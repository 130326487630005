import React from 'react'
import { Typography, Grid, Container } from '@material-ui/core'

import { parse } from 'node-html-parser'
import { Helmet } from 'react-helmet'

import { Tournament, Page, OtherTournament } from '../lib/createPages'
import { UserContextProvider, WpContextProvider } from '../contexts'
import { ResetPassword, Layout, Header, Logo, WPAst, OtherTournaments } from '../components'

interface HomeProps {
  pageContext: { tournament: Tournament; edges: { node: Page }[]; otherTournaments: OtherTournament[] }
}

const Home: React.FC<HomeProps> = ({ pageContext }) => {
  const node = parse(pageContext.tournament.content.replace(/\n/g, ''))

  return (
    <>
      <Helmet>
        <title>{pageContext.tournament.title} - Schacktävlingar för din skola på internet</title>
      </Helmet>
      <WpContextProvider tournament={pageContext.tournament}>
        <UserContextProvider>
          <ResetPassword />
          <Layout pageContext={pageContext}>
            <Header pageContext={pageContext} />
            <Container css={{ marginTop: 36 + 64, marginBottom: 36, maxWidth: 960 + 24 * 2 }}>
              <Grid container spacing={6}>
                <WPAst node={node} />

                <OtherTournaments otherTournaments={pageContext.otherTournaments} />

                <Grid
                  item
                  xs={12}
                  css={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', overflow: 'hidden' }}
                >
                  <Logo />
                  <Typography variant="h5" css={{ marginTop: 16 }}>
                    Vi gör Sverige smartare!
                  </Typography>
                </Grid>
              </Grid>
            </Container>
          </Layout>
        </UserContextProvider>
      </WpContextProvider>
    </>
  )
}

export default Home
